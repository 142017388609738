/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

body {
  font-family: 'Montserrat', sans-serif !important;
}

.f-kanit {
  font-family: 'Kanit', sans-serif !important;
}

.letter-spacing-10 {
  letter-spacing: 1px !important;
}

.letter-spacing-15 {
  letter-spacing: 1.5px !important;
}

.letter-spacing-20 {
  letter-spacing: 2px !important;
}

.letter-spacing-50 {
  letter-spacing: 5px !important;
}

.letter-spacing-60 {
  letter-spacing: 6px !important;
}

.letter-spacing-70 {
  letter-spacing: 7px !important;
}

.form-check-input.form-check-input-black {
  width: 25px;
  height: 25px;
  background-color: #467FAF !important;
  border: 1px solid white;
  border-radius: 4px;
}

.form-check-input.form-check-input-black:checked[type=checkbox] {
  background-color: white !important;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23004B89' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e") !important;

}

.form-check-input:checked[type=checkbox] {
  background-color: #467FAF !important;
}

/* -------------------------------------------------------------------------- */
/*                                  ANCHOR VH                                 */
/* -------------------------------------------------------------------------- */

@for $i from 1 through 10  {
  .vh-#{$i * 10} {
    height: #{$i * 10}vh !important;
  }
}


/* -------------------------------------------------------------------------- */
/*                             ANCHOR BACKGROUNDS                             */
/* -------------------------------------------------------------------------- */
.bg-center {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-primary {
  background-color: #002A50 !important;
}

.bg-primary-2 {
  background-color: #012B53 !important;
}

.bg-secondary {
  background-color: #004B89 !important;
}

.bg-secondary-2 {
  background-color: #467FAF !important;
}

.bg-tertiary {
  background-color: #F5F5F5 !important;
}

.bg-quaternary {
  background-color: #E3E3E3 !important;
}

.bg-transparent-dark{
  background-color: rgba(0, 0, 0, 0.4) !important
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR BANNERS                               */
/* -------------------------------------------------------------------------- */

.bg-banner-1 {
  background-image: url('./assets/media/img/banners/responsive/Nuevos/b1.png');
}

.bg-banner-2 {
  background-image: url('./assets/media/img/banners/responsive/Nuevos/b2.png');
}

.bg-banner-3 {
  background-image: url('./assets/media/img/banners/responsive/Nuevos/b3.png');
}

.text-1-banner-3 {
  font-size: calc(1.2rem + 1.2vh);
}

.text-2-banner-3 {
  font-size: calc(1.7rem + 1.7vh);
}

@media (min-width: 576px) {
  .bg-banner-1 {
      background-image: url('./assets/media/img/banners/responsive/Nuevos/b1.png');
  }

  .bg-banner-2 {
      background-image: url('./assets/media/img/banners/responsive/Nuevos/b2.png');
  }

  .bg-banner-3 {
      background-image: url('./assets/media/img/banners/responsive/Nuevos/b3.png');
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .bg-banner-1 {
      background-image: url('./assets/media/img/banners/responsive/Nuevos/b1.png');
  }

  .bg-banner-2 {
      background-image: url('./assets/media/img/banners/responsive/Nuevos/b2.png');
  }

  .bg-banner-3 {
      background-image: url('./assets/media/img/banners/responsive/Nuevos/b3.png');
  }

  .text-1-banner-3 {
      font-size: calc(1.7rem + 1.7vh);
  }
  
  .text-2-banner-3 {
      font-size: calc(2rem + 2vh);
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .bg-banner-1 {
      background-image: url('./assets/media/img/banners/responsive/Nuevos/b1.png');
  }

  .bg-banner-2 {
      background-image: url('./assets/media/img/banners/responsive/Nuevos/b2.png');
  }

  .bg-banner-3 {
      background-image: url('./assets/media/img/banners/responsive/Nuevos/b3.png');
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .bg-banner-1 {
      background-image: url('./assets/media/img/banners/responsive/Nuevos/b1.png');
  }

  .bg-banner-2 {
      background-image: url('./assets/media/img/banners/responsive/Nuevos/b2.png');
  }

  .bg-banner-3 {
      background-image: url('./assets/media/img/banners/responsive/Nuevos/b3.png');
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .bg-banner-1 {
      background-image: url('./assets/media/img/banners/responsive/Nuevos/b1.png');
  }

  .bg-banner-2 {
      background-image: url('./assets/media/img/banners/responsive/Nuevos/b2.png');
  }

  .bg-banner-3 {
      background-image: url('./assets/media/img/banners/responsive/Nuevos/b3.png');
  }
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR BUTTONS                               */
/* -------------------------------------------------------------------------- */
.btn-primary {
  background-color: #002A50 !important;
  border-color: #002A50 !important;
}

.btn-secondary {
  background-color: #467FAF !important;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR BORDERS                               */
/* -------------------------------------------------------------------------- */
.border-primary {
  border-color: #004B89 !important;
}

/* -------------------------------------------------------------------------- */
/*                             ANCHOR FONT WEIGHT                             */
/* -------------------------------------------------------------------------- */
.fw-800 {
  font-weight: 800 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

/* -------------------------------------------------------------------------- */
/*                                 ANCHOR TEXT                                */
/* -------------------------------------------------------------------------- */

.text-primary {
  color: #002A50 !important;
}

.text-secondary {
  color: #004B89 !important;
}

.text-secondary-2 {
  color: #D9D9D9 !important;
}

.text-tertiary {
  color: #467FAF !important;
}

.text-secondary {
  color: #004B89 !important;
}

.text-white {
  color: white !important;
}

.text-justify {
  text-align: justify !important;
}
.text-grey {
  color: grey !important;
}

.p-slider {
  height: 10px !important;
  
}